import React, { useMemo } from "react";
import { LayoutBox } from "../../../components/atoms/LayoutBox";
import Layout from "../../../components/Layout";
import { Widget } from "@typeform/embed-react";

const Nominate = (props) => {
  const searchParams = useMemo(
    () => new URLSearchParams(props.location.search),
    [props.location.search]
  );
  const tier = searchParams.get("tier");
  const onboarding_id = searchParams.get("id");

  return (
    <Layout title="Thank you!">
      <LayoutBox>
        <Widget
          id="nrAh7EI1"
          autoResize={true}
          inlineOnMobile={true}
          redirectTarget="_self"
          hidden={{
            tier,
            onboarding_id,
          }}
        />
      </LayoutBox>
    </Layout>
  );
};

export default Nominate;
